import { resolveUrlParams } from '@/lib/mdip/types'

// https://miro.com/app/board/uXjVM6rRBWI=/?moveToWidget=3458764566205536104&cot=14

// does extra work if mock is active
/* export const fetchMockMiddleware = async (
  url: string,
  options: RequestInit,
): Promise<Response> => {
  let localOptions = { ...options }
  //test if in mock mode
  if (url.includes('http://localhost:3001') || url.includes('lambda-url')) {
    const mockerMiddleware = (await import('./mockerMiddleware.js')).default
    localOptions = mockerMiddleware(localOptions, url)
  }

  return await fetch(url, localOptions)
} */

export interface MdipInternalFetchOptions {
  contentType?: 'auto'
  [key: string]: any
}

const getContentType = (options?: MdipInternalFetchOptions) => {
  // If contentType is auto, let the browser decide
  if (options?.contentType === 'auto') return
  if (options?.contentType != null) return options.contentType
  return 'application/json'
}

// adds auth header token
export const MdipInternal = async ({
  url,
  method,
  payload,
  params,
  options,
  sessionToken,
}: {
  url: string
  method: string
  payload?: any
  params?: any
  options?: MdipInternalFetchOptions
  sessionToken?: string
}) => {
  const contentType = getContentType(options)

  let localOptions: {
    headers: {
      [key: string]: any
    }
  } = {
    headers: {},
  }

  if (sessionToken) {
    localOptions.headers['authorization'] = `Bearer ${sessionToken}`
  }

  if (contentType != null) {
    localOptions.headers['Content-Type'] = contentType
  }

  if (params) url = resolveUrlParams(params, url)
  if (options?.headers?.ProxiedURL)
    options.headers.ProxiedURL = resolveUrlParams(
      params,
      options?.headers?.ProxiedURL,
    )

  const getBody = async () => {
    if (url.includes('http://localhost:3001') || url.includes('lambda-url')) {
      const mockerMiddleware = (await import('./mockerMiddleware.js')).default
      payload = mockerMiddleware(payload, method, url)
    }
    if (options && options?.contentType === 'auto') return payload
    return payload && JSON.stringify(payload)
  }
  const body = await getBody()
  return fetch(url, {
    next: {
      revalidate: (options?.revalidate ?? options?.cache) ? undefined : 60,
    },
    cache: options?.cache,
    method: method as string,
    body,
    headers: {
      ...localOptions.headers,
      ...options?.headers,
      // proxyHeadersContentType: options?.contentType,
    },
  })
}
