import { MdipClient } from '@/lib/mdip/client'
import { User } from '@/lib/mdip/types'
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

type UseDatasetsQueryProps = {
  config?: Omit<UseQueryOptions, 'queryKey'>
}

export const CURRENT_USER_QUERY_KEY = 'current_user'

export default function useCurrentUserQuery({ config }: UseDatasetsQueryProps) {
  const { data: session } = useSession()
  const sessionToken = session?.token
  return useQuery({
    gcTime: 1000 * 60,
    queryKey: [CURRENT_USER_QUERY_KEY],
    enabled: !!sessionToken,
    queryFn: async () =>
      MdipClient({
        url: '/api/v1/auth/users/current',
        method: 'get',
        options: { cache: 'no-store' },
        sessionToken,
      }),
    ...config,
  }) as UseQueryResult<User>
}
